@import 'utilities/variables';
@import 'utilities/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import "node_modules/bootstrap/scss/variables";

$theme-colors: $colors;

$popover-arrow-width: 2rem;
$popover-arrow-height: 1rem;
$enable-responsive-font-sizes: true;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1100px,
    xl: 1440px
);

$sizes: (
    10: 10%,
    15: 15%,
    20: 20%,
    30: 30%,
    40: 40%,
    45: 45%,
    60: 60%,
    70: 70%,
    80: 80%,
    90: 90%,
    10p: 10px,
    15p: 15px,
    20p: 20px,
    25p: 25px,
    30p: 30px,
    35p: 35px,
    40p: 40px,
    50p: 50px,
    60p: 60px,
    70p: 70px,
    80p: 80px,
    90p: 90px,
    100p: 100px,
    120p: 120px,
    130p: 130px,
    150p: 150px,
    180p: 180px,
    200p: 200px,
    250p: 250px,
    300p: 300px,
    350p: 350px,
    400p: 400px,
    500p: 500px,
    auto: auto
);

// spacing
$spacers: (
    10: 10px,
    20: 20px,
    30: 30px,
    40: 40px,
    50: 50px,
    60: 60px,
    70: 70px,
    80: 80px,
    90: 90px,
    100: 100px,
    110: 110px,
    120: 120px,
    150: 150px,
    200: 200px,
);

@import "node_modules/bootstrap/scss/bootstrap";
@import 'base/base';
