.mainCard {
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    /* padding: 8px; */
    /* margin-bottom: 24px; */
    border-radius: 8px;
    color:white;
    font-size: 1.1rem !important;
}

.nestedCard {
    background: #ffffff;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.cardHeader {
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    font-size: 1.3rem !important;
    color:white;
    font-weight: 'bolder' !important;
}

.cardTitle{
    padding-top: 5px;
}

.gridContainer {
    margin-top: 16px;
}

.gridItem {
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
}
