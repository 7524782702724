.footer-main {
  width: auto;
  background-color: white;
  margin-top: 15px;
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;

  h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 0;

    li {
      padding: 5px 0;

      &:hover {
        background-color: unset;
      }

      a {
        transition: 0.5s;
      }

      svg {
        width: 30px;
        vertical-align: top;
        margin-top: 5px;
        margin-right: 10px;
      }

      span {
        width: calc(100% - 30px);
        display: inline-block;
      }
    }
  }

  .copy-rights {
    padding: 15px 30px;
    background-color: #fafafa;
  }

  .footer {
    padding: 15px 30px;
  }
}
