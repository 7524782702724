body {
    /* height: 100%; */
    /* overflow: hidden; */
    padding: 0px;
    margin: 0px;
}

.zoom-80{
    zoom:80%
}

.zoom-70{
    zoom:70%
}

.formSec {
    background-image: url('../../assets/img/tricolor.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
}

.marginTop {
    padding-top: 5%;
}

.formdesign {
    background: #0d66d0;
    font-size: 14px;
    color: #fff;
    padding: 15px;
    width: 100%;
}

.formdesign2 {
    background: rgba(255, 255, 255, 0.9);
    padding: 40px;

}

.social-login a {
    border: 1.5px solid #c9c9c9;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
}

/* .social-login img {
    height: 50px;
} */

/* .RuleWithText {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #4b4b4b;
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
}

.RuleWithText:after,
.RuleWithText:before {
    content: "";
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-bottom: 1px solid #eaeaea;
} */

.btn-primary {
    border-width: 2px;
    border-style: solid;
    border-radius: 16px;
    min-height: 32px;
    height: auto;
    min-width: 72px;
    padding: 3.5px 14px 4.5px;
    font-size: 14px;
    font-weight: 600;
}

.form-control {
    /* border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0px;
    padding-left: 0px; */
}

.form-control:focus {
    box-shadow: none;
    border-bottom: 2px solid #86b7fe;
}

label {
    padding: 4px 0 5px;
    font-size: 12px;
    font-weight: 400;
}

.text-right {
    text-align: right;
}

.logoDiv {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 15%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media only screen and (min-width: 769px) {
    .desktopNone {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    /* .formSec {
        background: #fff !important;
    } */

    .formdesign2 {
        background: #fff;
        padding: 10px;
        padding-top: 40px;
    }
    .mobileNone{
        display: none;
    }
    .checkmark {
        width: 40px; 
        height: 20px; 
        border: 2px solid #ccc; 
        border-radius: 4px; 
        position: relative; 
        margin-right: 12px; 
        top: -8px;
        transition: background-color 0.3s, border-color 0.3s; 
        cursor: pointer;
    }
    .custom-checkbox input:checked + .checkmark::after {
        content: '';
        position: absolute;
        left: 10px;
        top: -11px;
        width: 10px;
        height: 20px;
        border: solid #046A38;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        transition: width 0.2s ease, height 0.2s ease;
    }
}
.facebook-login-btn{
    background: transparent;
}
.facebook-login-btn span {
    text-indent: -9999px;
  }
